<template>
  <div class="paper pt-0 pt-md-3 container">
    <div class="row paper-info justify-center">
      <div class="col-12 col-lg-6 offset-xl-1 col-xl-5 px-0 px-md-3">
        <ProductDetailSlider :items="images"/>
        <div
            v-if="['Matte', 'MetalPaper', 'Textured'].includes($route.name)"
            class="artist-container passive-grey--text"
        >
          <span>{{ $t("art-by") }} &nbsp; {{ artist }}</span>
          <span>Instagram &nbsp;{{ social }}</span>
        </div>
      </div>
      <div class="paper-texts-container col-12 col-lg-6 pl-lg-16">
        <h3 class="layout-title mb-2 mb-md-5 text-center text-lg-left">
          {{ title }}
        </h3>
        <div class="text-container">
          <p class="page-subtitle text-justify">{{ subtitle }}</p>
        </div>

        <ul class="pl-0">
          <li>· {{ feature_one }}</li>
          <li>· {{ feature_two }}</li>
          <li>· {{ feature_three }}</li>
          <li>· {{ feature_four }}</li>
          <li>· {{ feature_five }}</li>
          <li v-if="feature_six">· {{ feature_six }}</li>
        </ul>
        <input
            ref="file"
            accept="image/*"
            type="file"
            @change="uploadImage($event)"
        />
        <div class="footer">
          <AppButton @click="$refs.file.click()" color="primary" large>
            <IconUpload class="mr-2"/>
            {{ $t("buttons.upload-your-image") }}
          </AppButton>
          <div class="warranty-text">
            <IconBadge/>
            <span>{{ $t("buttons.warranty") }}</span>
          </div>
        </div>
        <!--        <span :data-id="_product_id" class="stamped-product-reviews-badge stamped-main-badge"></span>-->
      </div>
    </div>
  </div>
</template>

<script>
import {configuratorMixins} from "@/mixins/configuratorMixins";
import IconUpload from "@/assets/svg-icons/icons/upload.svg";
import IconBadge from "@/assets/svg-icons/icons/badge.svg";
import localforage from "localforage";
import {
  ACCEPTED_FILE_SIZE,
  ACCEPTED_FILE_TYPES, ACCEPTED_MAXIMUM_PIXEL_SIZE,
  UPLOAD_SERVICE_URL,
} from "@/data/UploadInformations";
import {isArray, isObject} from "lodash";
import {blankAxios} from "@/utils/setAxios";

export default {
  name: "PhotoPaperSection",
  components: {IconUpload, IconBadge},
  mixins: [configuratorMixins],
  data() {
    return {
      product_data: {
        id: null,
        product_type: null,
        image: "",
      },
    };
  },
  methods: {
    //! Sizing Methods
    detectSizes(minWidth, minHeight) {
      const orientation = this.detectOrientation(minWidth, minHeight);
      let sizes = [];
      const horizontal_sizes = [8, 16, 24, 30, 40];
      const vertical_sizes = [5, 8, 12, 16, 24, 35];
      const square_sizes = [7, 12, 18, 24, 35];
      const CANVAS_MAXIMUM_WIDTH = 750;
      const CANVAS_MAXIMUM_HEIGHT = 750;
      const MAX_WIDTH = 24 || Math.max(...horizontal_sizes);
      const MAX_HEIGHT = 16 || Math.max(...vertical_sizes);

      //! Yatay bir resim seçilmişse
      if (orientation === "horizontal") {
        const orientationRate = minWidth / minHeight;
        sizes = horizontal_sizes.map((size) => {
          const height = Math.round(size / orientationRate);
          const canvas_width = (size * CANVAS_MAXIMUM_WIDTH) / MAX_WIDTH;
          const canvas_height = canvas_width / orientationRate;
          return {
            width: size,
            height: height,
            label: `${size}" x ${height}"`,
            selected: size === MAX_WIDTH,
            canvas_size: {
              width: canvas_width,
              height: canvas_height,
            },
            pixel: {
              width: size * 96,
              height: height * 96,
            },
            suggested_size: true,
            custom_size: false,
          };
        });
      } else if (orientation === "vertical") {
        const orientationRate = minHeight / minWidth;
        sizes = vertical_sizes.map((size) => {
          const height = Math.round(size * orientationRate);
          const canvas_height = (height * CANVAS_MAXIMUM_HEIGHT) / MAX_HEIGHT;
          const canvas_width = canvas_height / orientationRate;
          return {
            height: height,
            width: size,
            label: `${size}" x ${height}"`,
            selected: size === MAX_HEIGHT,
            canvas_size: {
              width: canvas_width,
              height: canvas_height,
            },
            pixel: {
              width: size * 96,
              height: height * 96,
            },
            suggested_size: true,
            custom_size: false,
          };
        });
      } else {
        sizes = square_sizes.map((size) => {
          const canvas_width = (size * CANVAS_MAXIMUM_WIDTH) / MAX_HEIGHT;
          // const canvas_height = (size * CANVAS_MAXIMUM_HEIGHT) / MAX_HEIGHT;
          return {
            width: size,
            height: size,
            label: `${size}" x ${size}"`,
            canvas_size: {
              width: canvas_width,
              height: canvas_width,
            },
            pixel: {
              width: size * 96,
              height: size * 96,
            },
            selected: size === MAX_WIDTH,
            suggested_size: true,
            custom_size: false,
          };
        });
      }
      return sizes;
    },
    detectOrientation(width, height) {
      const _aspectRatio = height / width;
      if (_aspectRatio >= 0 && _aspectRatio < 1) return "horizontal";
      else if (_aspectRatio === 1) return "square";
      else if (_aspectRatio > 1) return "vertical";
    },
    uploadProcess(file) {
      //! Upload Süreci Başlar...
      const formData = new FormData();
      formData.append("file", file);
      blankAxios
          .post(UPLOAD_SERVICE_URL, formData, {
            onUploadProgress: progressEvent => {
              this.$store.commit("SET_PERCENTAGE_LOADER", {
                show: true,
                uploading: true,
                total: progressEvent.total,
                loaded: progressEvent.loaded,
                usePercentage: true
              })
            }
          })
          .then(async (upload_response) => {
            const {uploaded_file_info} = upload_response?.data || {}
            if (upload_response.status === 201) {
              this.$store.dispatch("SET_LOADER", {status: true, max: 100, current: 0})
              this.$store.commit("SET_LOADER_INITIAL")

              let image_library = [];
              // Set User Image Library
              uploaded_file_info.original.base64 = await this.convertFileToBase64(file);
              localforage
                  .getItem('uil')
                  .then((user_image_library) => {
                    const userLibraryObject = {
                      ...uploaded_file_info,
                      sizes: this.detectSizes(uploaded_file_info?.original?.width, uploaded_file_info?.original?.height),
                      picture_id: upload_response.data?.picture_id,
                      tempID: new Date().getTime(),
                      file,
                    };

                    if (isArray(user_image_library)) {
                      image_library = user_image_library.filter((image) => image.expire > new Date().getTime());
                      image_library.push(userLibraryObject);
                    } else {
                      image_library.push(userLibraryObject);
                    }

                    localforage
                        .setItem('uil', image_library)
                        .then(() => {
                          this.product_data.image = userLibraryObject;
                          localforage.setItem("picture_id", uploaded_file_info?.picture_id)
                          localforage
                              .setItem("product_data", this.product_data)
                              .then(() => {
                                // this.$store.dispatch("SET_LOADER", {status: false, max: 100, current: 0})
                                // this.$store.commit("SET_LOADER_INITIAL")
                                this.$store.commit("SET_PERCENTAGE_LOADER", {
                                  show: true,
                                  uploading: false,
                                })
                                setTimeout(() => {
                                  this.$store.commit("SET_PERCENTAGE_LOADER", {
                                    show: false,
                                    uploading: false,
                                    finishing: false
                                  })
                                }, 200)
                                this.$router.push({name: "Configurator"});
                              });
                        });
                  });
            }
          })
          .catch((e) => {
            document.querySelector("html").style.overflowY = "scroll";
            if(isObject(e?.response?.data?.errors)){
              const errorList = Object.values(e?.response?.data?.errors)?.flat(Infinity)
              const isSizeError = errorList.some(e => e.includes("width or height exceeds limit"))
              if(isSizeError) this.createWarningNotification(this.$t("notify.file_pixel_size_single"))
              else {
                errorList?.forEach(error => this.createWarningNotification(error))
              }
            }
            this.$store.commit("SET_PERCENTAGE_LOADER", {show: false})
          })
    },
    uploadImage(event) {
      let input = event.target;
      const file = event.target.files[0];
      this.checkFileSize(input);
      this.checkFileType(file)
      this.checkFilePixelSizes(file)
          .then(() => {
            this.uploadProcess(file)
          })
          .catch((uploaded_pixel_size) => {
            this.$refs["file"].value = null;
            return this.createWarningNotification(this.$t("notify.file_pixel_size", {
              maximum_pixel_size: ACCEPTED_MAXIMUM_PIXEL_SIZE,
              uploaded_pixel_size
            }))

          })
    },
    product_type_detector() {
      if (this.$route.name === "CanvasPaper") {
        this.product_data.product_type = "canvas_frame";
        this.product_data.id = 1;
      } else if (this.$route.name === "AcrylicPaper") {
        this.product_data.product_type = "hd_acrylic";
        this.product_data.id = 3;
      } else if (this.$route.name === "MetalPaper") {
        this.product_data.product_type = "aluminum";
        this.product_data.id = 2;
      } else if (this.$route.name === "Matte") {
        this.product_data.product_type = "matte_photo_paper";
        this.product_data.id = 4;
      } else if (this.$route.name === "Satin") {
        this.product_data.product_type = "satin_photo_paper";
        this.product_data.id = 5;
      } else if (this.$route.name === "Textured") {
        this.product_data.product_type = "textured_fine_art";
        this.product_data.id = 7;
      } else if (this.$route.name === "Hemp") {
        this.product_data.product_type = "hemp_fine_art";
        this.product_data.id = 8;
      } else if (this.$route.name === "Metallic") {
        this.product_data.product_type = "metallic_photo_paper";
        this.product_data.id = 6;
      }
    },
  },
  props: {
    src: {
      type: String,
      default: require("../../assets/images/product-type/product-0.png"),
    },
    images: {
      type: Array,
    },
    details: {
      type: Array,
    },
    title: {
      type: String,
    },
    subtitle: {
      type: String,
    },
    spec: {
      type: String,
    },
    feature_one: {
      type: String,
    },
    feature_two: {
      type: String,
    },
    feature_three: {
      type: String,
    },
    feature_four: {
      type: String,
    },
    feature_five: {
      type: String,
    },
    feature_six: {
      type: String,
    },
    artist: {
      type: String,
    },
    social: {
      type: String,
    },
  },
  computed: {
    _product_id() {
      return "canvas-paper";
    },
  },
  created() {
    this.product_type_detector();
  },
};
</script>

<style lang="scss" scoped>
h3 {
  line-height: 20px;
}

input {
  display: none;
}

ul {
  margin-top: unset;
  @media (--t) {
    margin-top: 5px;
  }
}

li {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.02em;
  color: #393939;
}

.page-subtitle {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 0.02em;
}

.artist-container {
  font-size: 16px;
  line-height: 1.5;
  padding-left: 20px;
  padding-top: 10px;
  letter-spacing: 0.02em;
  @media (--t) {
    position: absolute;
    padding-left: unset;
    margin: 0;
  }

  span {
    display: block;
  }
}

.layout-title {
  font-size: 30px !important;
  line-height: 100%;
  text-transform: uppercase !important;
  margin-top: 40px !important;
  @media (--t) {
    margin-top: 55px !important;
    font-size: 36px;
  }
}

.paper {
  padding-bottom: 20px;

  @media (--t) {
    padding-bottom: 100px;
  }

  .paper-texts-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 0 20px;

    > * {
      max-width: 550px;
    }

    @media (--t) {
      justify-content: unset;
      //padding-left: unset;
    }
  }

  .paper-info {
    padding-top: 20px;
    @media (--t) {
      padding-top: 50px;
    }

    .text-container {
      .layout-title {
        margin-top: 40px;

        @media (--t) {
        }
      }
    }

    .footer {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      @media (--t) {
        display: flex;
        align-items: flex-start;
      }
    }

    button {
      padding: 13px 16px;
      font-size: 16px;
      max-width: 320px;
      display: flex !important;
      flex-direction: row !important;
      justify-content: space-between !important;
      font-style: normal !important;
      font-weight: bold !important;
      line-height: 150% !important;
      letter-spacing: 0.02em !important;
      color: #ffffff !important;
      margin-top: 35px;
      @media (--t) {
        margin-top: 57px !important;
      }
    }

    .warranty-text {
      margin-top: 30px;
      display: flex;
      align-items: center;
      @media (--t) {
        margin-top: 20px;
      }
      @media (max-width: 600px) {
        margin-bottom: 30px;
      }

      span {
        display: inline-block !important;
        font-size: 32px !important;
        line-height: 34px !important;
        letter-spacing: 0.02em !important;
        margin-left: 10px !important;
        font-family: Basilia, "sans-serif" !important;
        font-style: normal !important;
        font-weight: 500 !important;
        color: #393939 !important;
      }
    }
  }
}
</style>
